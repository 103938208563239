 
 
import useSWR from 'swr';
const fetcher = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    if (!res.ok) {
      const error = new Error(data.message);
      throw error;
    }
  
    return data;
  };
const Categories = () => {
 

  const { data, error } = useSWR('https://zia1-git-main-ikerpaster.vercel.app/api/categories', fetcher);
 
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // if (!data) {
  //   return <div>Loading...</div>;
  // }

//   const cat = data.data;
// console.log("this is the job category walla:: ",data);
const loadingSkeleton = [1,2,3,4,5,6,7,8];
  return (
    <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-10'>
      { 
      !data ? (<>
     {loadingSkeleton.map((e)=>(
 <div   href="#" className='w-full h-20 animate-pulse hover:bg-gray-200 hover:text-gray-200 cursor-not-allowed m-3 shadow-2xl rounded-lg py-2 bg-gray-300 flex flex-col justify-center items-center'>
 <div className='text-2xl animate-pulse'> </div>
 <div className='font-semibold animate-pulse'></div>
 <div className='text-lg animate-pulse'></div>
</div>
     ))}
     

      </>):
       data?.data?.map((e,i) => (
        <a key={i} href={`https://job.alafnanhr.com/?c=${e.name}`} className='w-full hover:bg-green-400 hover:text-white cursor-pointer m-3 shadow-2xl rounded-lg py-2 bg-gray-200 flex flex-col justify-center items-center'>
          <div className='text-2xl'>+</div>
          <div className='font-semibold'>{e?.name}</div>
          <div className='text-lg'>{e?.jobs?.length} Jobs</div>
        </a>
      ))}
    </div>
  );
};

export default Categories;
