import moment from 'moment';
 
import React from 'react';
import { FaBriefcase, FaMapMarker, FaSuitcase } from 'react-icons/fa';

const JobCard = ({ data }) => {
  return (
    <a href={`/job-details?j=${data?._id}`} className='w-full text-gray-500 border border-dashed p-4 m-3 bg-white shadow-2xl rounded-md cursor-pointer hover:bg-green-100 animations'>
      <div className='flex justify-between'>
        <span className='bg-green-500 text-sm flex items-center px-2 text-white'> {data?.jobType} </span>
        <span className='bg-yellow-500 text-xs flex items-center py-1 px-2 rounded-full text-gray-100 hover:bg-green-400 animations'>{data.status}</span>
      </div>

      <div className='text-sm py-2 mt-2 text-md bg-gray-100 p-2 rounded-lg shadow-lg text-gray-500'>
        {data?.title}
      </div>

      <div className='flex items-center gap-2 mt-5 '>
          <FaSuitcase className='h-4 ' />
          <span className='font-semibold'>
            Industry:
          </span>
          <span className='font-semibold text-yellow-900 truncate'>{data?.category}</span>
        </div>
      <div className='mt-2'>
        <div className='flex items-center gap-2'>
          <FaBriefcase className='h-4 ' />
          <span className='font-semibold'>
            Experience:
          </span>
          <span>
            {data?.experience}
          </span>
        </div>
      </div>

      <div className='flex justify-between items-center py-2'>
        <div className='flex items-center text-xs'> <FaMapMarker className='h-4 ' />
          <span className='mx-1'>
            {data.location.country} - {data.location.state} - {data.location.city}
          </span>
        </div>
        <div className=''> {moment(data.createdAt).fromNow()} </div>
      </div>
    </a>
  );
}

export default JobCard;
