import Categories from "../components/Categories";

export default function Home() {
  return (

    <main className="h-fit bg-green-50 w-full mt-4 my-4">
      <div className='mx-5'>
        <Categories />
      </div>
    </main>


  )
}
